import { template as template_702f3ba25144478c8f667522942719eb } from "@ember/template-compiler";
const FKLabel = template_702f3ba25144478c8f667522942719eb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
