import { template as template_27e59a05821d4d7d810a58f7bcf4bf85 } from "@ember/template-compiler";
const FKControlMenuContainer = template_27e59a05821d4d7d810a58f7bcf4bf85(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
